function getUrlVars() {
    var vars = [], hash;
    var hashes = window.location.href
        .slice(window.location.href.indexOf("?") + 1)
        .split("&");

    for (var i = 0; i < hashes.length; i++) {
        hash = hashes[i].split("=");
        vars.push(hash[0]);
        vars[hash[0]] = hash[1];
    }

    return vars;
}

function getOriginWithExplicitPort() {
    const protocol = window.location.protocol;
    let port = window.location.port;

    // 如果端口为空，则根据协议添加默认端口
    if (!port) {
        if (protocol === 'http:') {
            port = '80';
        } else if (protocol === 'https:') {
            port = '443';
        }
    }

    // 直接拼接 origin 和显式指定的端口
    return `${protocol}//${window.location.hostname}:${port}`;
}

function isDevelopment() {
    // 检测在开发环境还是在生产环境下，然后使用不同的企业微信登录
    const currentUrl = window.location.href;

    // 检查 URL 是否包含 'test'
    if (currentUrl.includes('test')) {
      return true
    } else {
      return false
    }
}

function detectDevice() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    let result = ''

    if (/wxwork/i.test(userAgent)) {
        if (/Mobile/i.test(userAgent)) {
            // 手机企业微信端
            result = "mobile_enterprise_wechat";
        } else {
            // 电脑企业微信端
            result = "computer_enterprise_wechat";
        }
    } else if (/Mobile/i.test(userAgent)) {
        // 手机浏览器端
        result = "mobile_browser";
    } else if (/Windows NT|Macintosh|X11/i.test(userAgent)) {
        // 电脑浏览器端
        result = "computer_browser";
    } else {
        // 位置环境，默认返回电脑浏览器端
        result = "computer_browser";
    }

    return result
}


export {
    getUrlVars,
    getOriginWithExplicitPort,
    isDevelopment,
    detectDevice,
}
