import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'

import styles from './index.module.css'
import useUserLevel from '../../../../utils/useUserLevel'


const ExperienceValueScrolling = () => {
    // 从 Redux store 获取经验值
    const current_experience = useSelector(state => state.get('home').get('current_experience'))
    const [displayedExperience, setDisplayedExperience] = useState(current_experience)
    const [isAnimating, setIsAnimating] = useState(false)
    const { level, totalExperience } = useUserLevel(current_experience);
    const expValueRef = useRef(null); // 创建一个 ref

    // 当经验值变化时，启动动画
    useEffect(() => {
        if (current_experience !== displayedExperience) {
            setIsAnimating(true) // 开始动画

            const timeoutId = setTimeout(() => {
                // 动画结束后更新显示经验值
                setDisplayedExperience(current_experience)
                setIsAnimating(false) // 重置动画状态
            }, 500) // 等待动画完成，与 CSS 动画时长相匹配

            // 清除 timeout，以避免不必要的 state 更新
            return () => clearTimeout(timeoutId);
        }
    }, [current_experience, displayedExperience])

    useEffect(() => {
        if (expValueRef.current) {
            const expContainer = expValueRef.current.parentNode;
            expContainer.style.width = `${expValueRef.current.offsetWidth}px`;
        }
    }, [current_experience, isAnimating]); // 当经验值或动画状态改变时重新计算宽度

    return (
        <div className={styles.main}>
            <div className={styles.exp_container}>
                {/* 当前显示的经验值 */}
                <div  ref={expValueRef} className={`${styles.exp_value} ${isAnimating ? styles.slide_out_and_fade : ''}`}>
                    {displayedExperience}
                </div>

                {/* 新的经验值，只在动画期间显示 */}
                {isAnimating && (
                    <div className={`${styles.exp_value} ${styles.slide_up_and_fade}`}>
                        {current_experience}
                    </div>
                )}
            </div>

            <div className={styles.total}>/{totalExperience} 次</div>
        </div>
    )
}

export default ExperienceValueScrolling